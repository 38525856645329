import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

import Image from 'next/image';
import { useTranslation, Trans } from 'next-i18next';

export default function Example2(
  {
    selectedInput,
    passSelectedInput,
    inputTypes,
    passOpenModal,
    subscription,
    customModal
  },
  ...props
) {
  const { t } = useTranslation('common');
  const setSelectedInput = (input) => {
    if (input.pro_feature && !subscription) {
      passOpenModal(true);
    } else if (input.custom_modal) {
      customModal(true);
    } else passSelectedInput(input);
  };

  return (
    <Listbox value={selectedInput} onChange={setSelectedInput}>
      {({ open }) => (
        <div>
          {/* <Listbox.Label className="block text-xs font-medium text-gray-700">Assigned to</Listbox.Label> */}
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default bg-white rounded-2xl border border-slate-300 font-medium py-2.5 pl-3 pr-10 text-left shadow-sm focus:border-neutral-100 focus:outline-none focus:ring-1 focus:ring-gray-300 text-sm">
              <span className="inline-flex w-full truncate">
                <span className="truncate">
                  {selectedInput
                    ? t(selectedInput.public_name)
                    : 'No Style Available'}
                </span>
                <span className="ml-2 truncate text-gray-500">
                  {/* {selectedInput.username} */}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-[#4138C2]"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              // as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="text-xs absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 md:text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 ">
                {inputTypes.map((item, index) => (
                  <Listbox.Option
                    key={item.public_name ? item.public_name : index}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-500' : 'text-gray-900',
                        'relative cursor-default select-none '
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <div className="h-full">
                        <div
                          className={classNames(
                            item.pro_feature ? '' : '',
                            'grid grid-rows-6 items-center h-full'
                          )}
                        >
                          <Image
                            src={item.inspiration_images[0]}
                            priority={true}
                            alt={item.public_name}
                            className={classNames(
                              item.pro_feature && !subscription
                                ? 'brightness-50 hover:brightness-100'
                                : '',
                              ' items-center row-span-5 object-cover w-full h-full aspect-square'
                            )}
                            // sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                            // fill={true}
                            height="256"
                            width="256"
                          />
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'truncate row-span-1 pl-2 text-xs'
                            )}
                          >
                            {t(item.public_name)}
                          </span>
                          {/* <span
                            className={classNames(
                              active ? 'text-indigo-200' : 'text-gray-500',
                              'ml-2 truncate'
                            )}
                          >
                            {item.description}
                          </span> */}
                          {item.featured_new ? (
                            <span className="absolute right-4 inline-flex items-center rounded bg-indigo-700 px-2 font-bold py-0.5 text-xs font-medium text-white">
                              New
                            </span>
                          ) : null}

                          {item.pro_feature && !subscription && (
                            <span className="absolute right-2 top-2 inline-flex items-center rounded bg-indigo-700 px-2 font-bold py-0.5 text-xs font-medium text-white">
                              Pro
                            </span>
                          )}
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              ''
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
