import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
import { useTranslation, Trans } from 'next-i18next';
export default function Example2(
  {
    selectedInput,
    passSelectedInput,
    inputTypes,
    passOpenModal,
    subscription,
    customModal
  },
  ...props
) {
  const { t } = useTranslation('common');

  const setSelectedInput = (input) => {
    if (input.pro_feature && !subscription) {
      passOpenModal(true);
    } else if (input.custom_modal) {
      customModal(true);
    } else passSelectedInput(input);
  };

  return (
    <Listbox value={selectedInput} onChange={setSelectedInput}>
      {({ open }) => (
        <div>
          {/* <Listbox.Label className="block text-xs font-medium text-gray-700">Assigned to</Listbox.Label> */}
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default bg-white rounded-2xl border border-slate-300 font-medium py-2.5 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-black text-sm">
              <span className="inline-flex w-full truncate">
                <span className="truncate">
                  {t(selectedInput.display_name)}
                </span>
                <span className="ml-2 truncate text-gray-500">
                  {selectedInput.username}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-[#4138C2]"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              // as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                {inputTypes.map((item) => (
                  <Listbox.Option
                    key={item.display_name}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-500' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <div>
                        <div className="flex flex-col md:flex-row">
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'truncate'
                            )}
                          >
                            {t(item.display_name)}
                          </span>
                          <span
                            className={classNames(
                              active ? 'text-indigo-200' : 'text-gray-500',
                              'ml-2 truncate'
                            )}
                          >
                            {t(item.description)}
                          </span>
                          {item.featured_new ? (
                            <span className="absolute right-4 inline-flex items-center rounded bg-indigo-700 px-2 font-bold py-0.5 text-xs font-medium text-white">
                              New
                            </span>
                          ) : null}

                          {item.pro_feature && !subscription && (
                            <span className="absolute right-4 inline-flex items-center rounded bg-indigo-700 px-2 font-bold py-0.5 text-xs font-medium text-white">
                              Pro
                            </span>
                          )}
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
